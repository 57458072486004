import React from 'react'
import { bool, string, number } from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { stripUnit } from 'polished'
import { useIntl } from 'gatsby-plugin-intl'

import { media } from 'styles/media'
import { white, greyLight, greyDark, black } from 'styles/colors'
import { boxShadow, cover } from 'styles/helpers'
import { animationTime, animationCurve } from 'styles/variables'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import Icon from 'components/Icon'
import SmallCaps from 'components/SmallCaps'
import BookButton from 'components/button/BookButton'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import GatsbyImage from 'gatsby-image'

const Container = styled.div`
  display: block;
  text-align: left;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  ${media.medium`
    display: flex;
    align-items: center;
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  `};
`

const FigureWrap = styled(Link)`
  position: relative;
  background-color: ${black};
  display: block;
  overflow: hidden;

  ${media.medium`
    width: 75%;
  `};

  &:after {
    content: '';
    width: 100%;
    padding-bottom: 62.5%;
    display: block;
  }
`

const Figure = styled(GatsbyImage)`
  ${cover('absolute')};
  position: absolute !important;

  object-fit: cover;
  transition: transform ${animationTime} ${animationCurve},
    opacity ${animationTime} ${animationCurve};

  ${Container}:hover & {
    transform: scale(1.05);
    opacity: 0.95;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 10;
  padding: ${spacing.large};
  background-color: ${white};
  margin-left: ${spacing.medium};
  margin-right: ${spacing.medium};
  margin-top: -${spacing.xxxLarge};
  ${boxShadow};

  ${media.small`
    margin-left: ${spacing.large};
    margin-right: ${spacing.large};
    padding: ${spacing.xLarge};
  `};

  ${media.medium`
    width: 45%;
    margin-left: ${props => (props.reverse ? 0 : '-20%')};
    margin-right: ${props => (props.reverse ? '-20%' : 0)};
    margin-top: 0;
  `};

  ${media.large`
    padding: ${spacing.xxLarge};
  `};

  ${media.xLarge`
    margin-left: ${props => (props.reverse ? 0 : '-15%')};
    margin-right: ${props => (props.reverse ? '-15%' : 0)};
    padding: 48px;
  `};
`

const StyledHeading = styled(Heading)`
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};

  ${media.medium`
    margin-bottom: ${spacing.medium};
  `};

  ${media.xLarge`
    margin-bottom: ${stripUnit(spacing.medium) * 1.25 + 'px'};
  `};
`

const Description = styled.div`
  position: static;

  ${media.large`
    margin-bottom: ${spacing.xxLarge};
  `};

  ${media.xLarge`
    margin-bottom: ${spacing.xxxLarge};
  `};
`

const Meta = styled.div`
  display: block;
  margin-bottom: ${spacing.large};

  ${media.xLarge`
    margin-bottom: ${spacing.xLarge};
  `};
`

const MetaItemLabel = styled(SmallCaps)`
  color: ${greyDark};
`

const MetaItemIconWrap = styled.div`
  display: flex;
  margin-right: ${spacing.small};
  color: ${greyLight};
`

const MetaItemContainer = styled.div`
  display: inline-flex;
  align-items: center;

  margin-right: ${spacing.medium};

  ${media.medium`
      margin-right: ${spacing.large};
    `};

  ${media.xLarge`
      margin-right: ${spacing.xLarge};
    `};
`

const RoomPreview = ({
  name,
  beds,
  size,
  to,
  description,
  reverse,
  fluid,
  occupancy = [],
  bookingUrl,
  bookingTarget,
}) => {
  const intl = useIntl()

  const occupancyString = occupancy
    .map(({ adult, child }) => {
      let res = ''

      res += intl.formatMessage(
        { id: 'occupancyCount' },
        {
          adultCount: Number(adult),
          childCount: Number(child),
        }
      )

      return res
    })
    .join(` ${intl.formatMessage({ id: 'or' })} `)

  return (
    <Container reverse={reverse}>
      <FigureWrap to={to}>
        <Figure fluid={fluid} />
      </FigureWrap>

      <Content reverse={reverse}>
        <StyledHeading size={2}>{name}</StyledHeading>

        {beds && size && occupancyString && (
          <Meta>
            {beds && (
              <MetaItem
                icon='bed'
                label={`${beds} ${intl.formatMessage({ id: 'bed' })}`}
              />
            )}

            {size && (
              <MetaItem
                icon='size'
                label={`${size} ${intl.formatMessage({ id: 'sqft' })}`}
              />
            )}

            {occupancyString && (
              <MetaItem icon='occupants' label={occupancyString} />
            )}
          </Meta>
        )}

        <Description>{description}</Description>

        <ButtonGroup>
          <BookButton url={bookingUrl} target={bookingTarget} />

          <Button to={to} theme='secondary'>
            {intl.formatMessage({ id: 'details' })}
          </Button>
        </ButtonGroup>
      </Content>
    </Container>
  )
}

RoomPreview.propTypes = {
  name: string.isRequired,
  beds: number.isRequired,
  size: number.isRequired,
  slug: string.isRequired,
  description: string.isRequired,
  reverse: bool,
}

const MetaItem = ({ icon, label }) => (
  <MetaItemContainer>
    <MetaItemIconWrap>
      <Icon icon={icon} size='small' />
    </MetaItemIconWrap>
    <MetaItemLabel>{label}</MetaItemLabel>
  </MetaItemContainer>
)

MetaItem.propTypes = {
  icon: string.isRequired,
  label: number.isRequired,
}

export default RoomPreview
