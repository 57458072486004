import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import RoomPreview from 'components/RoomPreview'
import { RichText } from 'prismic-reactjs'

import Heading from 'components/Heading'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'

const Offers = ({ data }) => {
  const intl = useIntl()

  const {
    headerImage: {
      childImageSharp: { fluid },
    },
  } = data

  return (
    <Layout>
      <MetaTags
        title={`${intl.formatMessage({ id: 'ourOffers' })} | Hotel Griffintown`}
      />
      <Header
        eyebrow={intl.formatMessage({ id: 'ourOffers' })}
        heading={intl.formatMessage({ id: 'offer.heading' })}
        fluid={fluid}
      />

      {data.prismic.allOffer_categorys.edges.map(({ node }) => {
        const offers = data.prismic.allOffers.edges.filter(
          ({ node: offer }) => offer?.category?._meta.uid === node._meta.uid
        )

        if (offers.length < 1) return null

        return (
          <Section id={node._meta.uid}>
            <Wrapper size='narrow'>
              <SectionBlock>
                <GridRow>
                  <GridItem xs='1-1' lg='3-4'>
                    <Heading
                      size={2}
                      variant='sans'
                      css={`
                        margin-bottom: 0.5em;
                      `}
                    >
                      {node.title}
                    </Heading>

                    {node.description && RichText.render(node.description)}
                  </GridItem>
                </GridRow>
              </SectionBlock>
            </Wrapper>

            {offers.map(({ node }, i) => {
              const slug = node._meta.uid
              const fluid = node?.featured_pictureSharp?.childImageSharp?.fluid

              return (
                <Section flushTop={i % 2 !== 0}>
                  <Wrapper>
                    <RoomPreview
                      name={node.title}
                      description={RichText.render(node.short_description)}
                      to={`/offers/${slug}`}
                      fluid={fluid}
                      reverse={i % 2 === 0}
                      bookingUrl={node?.booking_link?.url}
                      bookingTarget={node?.booking_link?.target}
                    />
                  </Wrapper>
                </Section>
              )
            })}
          </Section>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query Offers($language: String!) {
    headerImage: file(name: { eq: "GH-2020-10-25_03288_RThibodeau" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismic {
      allOffer_categorys(lang: $language, sortBy: sort_ASC) {
        edges {
          node {
            hidden
            description
            title
            _meta {
              uid
            }
          }
        }
      }
      allOffers(lang: $language, sortBy: sort_ASC) {
        edges {
          node {
            title
            featured_picture
            featured_pictureSharp(crop: "apartmentPageCard") {
              childImageSharp {
                fluid(maxWidth: 900, maxHeight: 563) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
            category {
              ... on PRISMIC_Offer_category {
                _meta {
                  uid
                }
              }
            }
            booking_link {
              ... on PRISMIC__ExternalLink {
                target
                url
              }
            }
            short_description
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

export default Offers
